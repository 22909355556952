/* width */
::-webkit-scrollbar {
    width: 3px;

}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #223253;
    border-radius: 10px;
}

/* /////scroll box */
.boxscroll {
    position: sticky;
    top: 0px;
    background-color: #223253;
    z-index: 2;
    color: aliceblue;
    padding: 8px;
    transition: background-color 0.5s ease;
    margin-bottom: 10px;
    border-radius: 10px;
    display: block;
    width: 100%;

}

.hoverbox {
    opacity: 0 !important;
    transition: all 0.3s ease-in-out !important;
    transform: scale(0.3);
}

.hoverbox:hover {

    opacity: 1 !important;
    transition: all 0.3s ease-in-out !important;
    transform: scale(1);
}


.approvebox {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    transition: background-color 0.7s ease-in-out;
}

.hoverEffect:hover {
    background-color: #eeeeee;
    border-radius: 100%;
    transition: background-color 0.7s ease-in-out,
}